import { LOGIN_TYPES } from "../../types/loginTypes";
import { postMethod, getMethod, putMethod } from "../../../helper/api";
import { Cookies } from 'react-cookie';
import {notify} from 'react-notify-toast';
import { navigate } from "gatsby";


export const loginFail = data => {
    return {
      type: LOGIN_TYPES.GET_LOGIN_DETAILS_FAILURE,
      data: data
    };
  };
  export const loginSuccess = data => {
    return {
      type: LOGIN_TYPES.GET_LOGIN_DETAILS_SUCCESS,
      data: data
    };
  };
  export const updateProfileSuccess = data => {
    return {
      type: LOGIN_TYPES.PROFILE_UPDATE_SUCCESS,
      data: data
    };
  };

  export const switchUser = () => async dispatch => {
    try {
          
      dispatch(loginSuccess({}));
    } catch (e) {
      //console.log(e);
      loginFail(e);
    }
  };

export const loginCheck = userObj => async dispatch => {
    try {
     
        const result = await postMethod(`/auth/firebase`, userObj, "", {
        });
       
        if (result.status === 200) {
	        let role = window.localStorage.getItem('role');
	        let fcmTokenFromIOS = window.localStorage.getItem('fcmTokenFromIOS');
	        window.localStorage.clear();
          let cookies =  new Cookies();
          cookies.set('token',result.data.jwt,{'path':'/'});
          cookies.set('idToken',userObj.token,{'path':'/'});
          window.localStorage.setItem('token',result.data.jwt);
	        window.localStorage.setItem('idToken',userObj.token);
          window.localStorage.setItem('role',role);
	        if(fcmTokenFromIOS!==null) window.localStorage.setItem('fcmTokenFromIOS',fcmTokenFromIOS);
          dispatch(loginSuccess(result.data));
          //dispatch(getProfile());
        } else {
            
        }
    } catch (e) {
      //console.log(e);
      loginFail(e);
    }
  };

  export const addUser = (userObj, userDetails) => async dispatch => {
    try {
     
        const result = await postMethod(`/auth/firebase`, userObj, "", {
        });
       
        if (result.status === 200) {
          let cookies =  new Cookies();
          cookies.set('token',result.data.jwt,{'path':'/'});
          cookies.set('idToken',userObj.token,{'path':'/'});
          window.localStorage.setItem('token',result.data.jwt);
          window.localStorage.setItem('idToken',userObj.token); 
          
          
          await dispatch(updateProfile(userDetails,result.data.user.id,false))
          await dispatch(loginSuccess(result.data));
            navigate('/dashboard',{replace:true});
          //dispatch(getProfile());
        } else {
            
        }
    } catch (e) {
      //console.log(e);
      loginFail(e);
    }
  };

  export const updateProfile = (userObj,id, redirect=true) => async dispatch => {
    try {
      
        const result = await putMethod(`/users/${id}`, userObj, "", {
        });
        if (result.status === 200) {
		      //console.log(result);
          //dispatch(loginSuccess(result.data));
          await dispatch(getProfile());
          notify.show("Profile Saved Successfully", "success");
          if(redirect)
            setTimeout(()=>{navigate(-1)},500);
        } else {
          notify.show(result.response.data.message[0].messages[0].message, "error");
        }
    } catch (e) {
      notify.show("Server Error! Please try again later.", "error");
      //console.log(e);
      loginFail(e);
    }
  };

  export const getProfile = (id) => async dispatch => {
    try {
      //console.log("profile called");
        const result = await getMethod(`/users/me`, "", {
        });
        if (result.status === 200) {
          let cookies =  new Cookies();
          
          let userObj = {'jwt' : window.localStorage.getItem('token'), user: result.data};
          
          
          dispatch(loginSuccess(userObj));
          //console.log(userObj,"result.data")
          //dispatch(updateProfileSuccess(result.data.success));
        } else {
            
        }
    } catch (e) {
      //console.log(e);
      loginFail(e);
    }
  };
