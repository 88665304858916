/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import jwt_decode from "jwt-decode";
import {Cookies} from 'react-cookie';
import {connect, useDispatch} from 'react-redux';
import {getProfile} from '../state/actions/login/loginAction';
import Nav from "../components/nav"
import "../css/bootstrap.min.css"
import "../css/styles.css"
import "../css/icomoon.css"
import { navigate } from "gatsby";
import { postMethod } from "../helper/api"

const Layout = ({ children, userDetails, title, disabled }) => {


  //console.log(disabled);
  const dispatch = useDispatch();

  let cookies = new Cookies();

  React.useEffect(()=>{
    function playSound(){
      try{
      let ding = new Audio('https://app.121islam.com/ding-ding-sound-effect.mp3');
	    ding.play();
      }catch(e){

      }
    }

    function receiveNotificationMessage(e){

      if(e.data==='NotificationReceived'){
        try{
        let nevent = new Event("playNotificationSound");
        window.dispatchEvent(nevent);
        }catch(e){
          
        }
      }
    }

    window.addEventListener("playNotificationSound",playSound);

    //window.addEventListener("message",receiveNotificationMessage);

    

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("notifySound", playSound);
      //window.removeEventListener("message",receiveNotificationMessage);
    };
  },[])

  if(typeof window !== "undefined" && disabled===undefined){

   	  
  //console.log("layoutToken:",window.localStorage.getItem('token'));
  if(window.localStorage.getItem('token')!==undefined && window.localStorage.getItem('token')!==null && !userDetails){
    let decoded = jwt_decode(window.localStorage.getItem('token'));
    dispatch(getProfile(decoded.id));
  }else if (window.localStorage.getItem('token')!==undefined){
    //navigate('/dashboard?v='+window.localStorage.getItem('appVersion'));
  }else if(window.localStorage.getItem('idToken')===undefined){
    const isBrowser = typeof window !== "undefined";
    if(isBrowser) navigate('https://app.121islam.com/?v='+window.localStorage.getItem('appVersion'));
  }

  return (
    <div className="page-wrapper animated fadeInLeft">
    <>
      {(window.localStorage.getItem('token') || window.localStorage.getItem('idToken')) && (<Nav user={userDetails} title={title} />)}
      {(window.localStorage.getItem('token') || window.localStorage.getItem('idToken')) && (children)}
    </>
    </div>
  )
  } else if (disabled!==undefined){
    return <>{children}</>
  }
  else{
	 return <div className="page-wrapper animated fadeInLeft">{children}</div>
   }
}

export default connect(state=>({userDetails:state.loginReducer.authenticate.user}),null)(Layout);
